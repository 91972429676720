:root {
  --primary-font: "Fira Sans", sans-serif;
  --secondary-font: "atrament-web", sans-serif;
  --color-light: #fff;
  --color-dark: #000;
  --color-accent: #ed2024;
  --text-dark: #333;
  --text-light: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  font-family: var(--primary-font);
  height: 100%;
  background-color: #000;
  transition: background-color ease-in-out 500ms;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  background-color: #000;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-accent);
  border-radius: 4px;
}

#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--secondary-font);
  font-weight: 400;
}

a.active {
  background-color: #4e4d4d;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
}

.slick-slider button.slick-arrow.slick-prev,
.slick-slider button.slick-arrow.slick-next {
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: fit-content;
  width: fit-content;
}

.slick-slider button.slick-arrow.slick-next {
  left: unset;
  right: 15px;
}

.slick-slider button.slick-arrow.slick-prev::before,
.slick-slider button.slick-arrow.slick-next::before {
  transition: opacity ease-in-out 250ms;
  color: var(--color-accent);
  font-size: 50px;
}

.premium-brands-slider {
  opacity: 0;
}

.premium-brands-slider .slick-slide {
  transform: scale(0.9);
  transition: transform ease-in-out 250ms;
  transition-delay: 125ms;
}

.premium-brands-slider .slick-slide .overlay {
  position: absolute;
  top: 0;
  display: block;
  background-color: #000;
  opacity: 0.3;
  height: 100%;
  width: 100%;
  z-index: 2;
  transition: opacity ease-in-out 250ms;
  pointer-events: none;
  transition-delay: 125ms;
}

.premium-brands-slider .slick-center {
  transform: scale(1);
}

.premium-brands-slider .slick-center .overlay {
  opacity: 0;
}

.premium-brands-dots {
  display: flex !important;
  justify-content: center;
  gap: 25px;
  list-style: none;
  margin-top: 4rem;
}

.premium-brands-dots button {
  border: none;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  color: transparent;
}

.premium-brands-dots .slick-active button {
  background-color: var(--color-accent);
}

.racing-services .slick-list {
  margin: 0 !important;
}

.core-values-slider .slick-list {
  margin: 0 0 30px 0 !important;
}

.core-values-slider .slick-list .slick-slide {
  opacity: 0.25;
  transition: opacity ease-in-out 250ms;
}

.core-values-slider .slick-list .slick-slide.slick-active {
  opacity: 1;
}

.core-values-slider .slick-dots li.slick-active button:before {
  color: var(--color-accent);
}

.core-values-dots {
  display: flex !important;
  justify-content: center;
  gap: 10px;
  list-style: none;
  margin-bottom: 6rem;
}

.core-values-dots button {
  border: none;
  width: 50px;
  height: 4px;
  border-radius: 2px;
  color: transparent;
}

.core-values-dots .slick-active button {
  background-color: var(--color-accent);
}

@media (width > 400px) {
  .core-values-dots button {
    width: 65px;
  }
}

@media (width >= 768px) {
  .core-values-slider .slick-list .slick-slide {
    padding-bottom: 30px;
  }
}

@media (width >= 1200px) {
  .core-values-slider .slick-list .slick-slide {
    padding-bottom: 130px;
  }
}
