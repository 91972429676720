.teamMembers {
  padding-bottom: 4rem;
}

.teamMembersContainer {
  padding: 0 1rem;
}

.teamMembersSelectorMobile {
  position: relative;
}

.teamMembersSelectorMobile::after {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 18.5px;
  right: 20px;
  height: 20px;
  width: 20px;
  font: var(--fa-font-solid);
  content: "\f078";
  font-family: "Font Awesome\ 6 Free";
  font-weight: 900;
  color: var(--text-light);
  margin: 0;
  padding: 0;
  pointer-events: none;
}

.teamMembersSelect {
  position: relative;
  margin: 0 auto 2rem 0;
  padding: 1rem 3rem 1rem 1rem;
  font-size: 18px;
  background-color: var(--color-dark);
  color: var(--text-light);
  text-transform: uppercase;
  border-radius: 27px;
  border: 1px solid var(--color-accent);
  font-family: var(--primary-font);
  font-weight: bold;
  cursor: pointer;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
}

.teamMembersSelect:focus-visible {
  border: 1px solid var(--color-accent);
  outline: 1.5px solid var(--color-accent);
}

.teamMembersSelect option {
  font-family: var(--primary-font);
  text-transform: uppercase;
}

.teamMembersSelect option:checked,
.teamMembersSelect option:focus-within {
  background-color: var(--color-accent);
}

.teamProfilesMobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 80px;
}

.profileImageContainer {
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid var(--color-light);
  margin-bottom: 0.25rem;
  transition: border ease-in-out 350ms;
}

.profileImageContainer:hover {
  border: 2px solid var(--color-accent);
}

.profileImageContainer img {
  width: 100%;
  transition: transform ease-in-out 250ms;
}

.profileImageContainer img:hover {
  transform: scale(1.025);
}

.profileName {
  color: var(--text-light);
  text-align: center;
  text-transform: uppercase;
  font-size: 26px;
  font-family: var(--secondary-font);
}

.jobTitle {
  color: var(--color-accent);
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.teamMembersSelector {
  display: none;
}

.teamMembersDisplayContainer,
.teamMembersDisplayContainer2 {
  display: none;
}

@media (width >= 425px) {
  .teamProfilesMobile {
    grid-template-columns: 1fr 1fr;
  }
}

@media (width >= 768px) {
  .teamMembersSelectorMobile,
  .teamProfilesMobile {
    display: none;
  }

  .teamMembersSelector {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem 1rem;
    margin-bottom: 2rem;
  }

  .departmentButton {
    padding: 0.5rem 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: var(--text-light);
    transition: background-color ease-in-out 250ms, color ease-in-out 250ms;
  }

  .departmentButton.active {
    color: var(--text-dark);
    background-color: var(--color-light);
  }

  .departmentButton:hover:not(.active) {
    color: var(--color-accent);
  }

  .teamMembersDisplayContainer,
  .teamMembersDisplayContainer2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    max-width: 900px;
    margin: 3rem auto;
  }

  .teamMembersDisplayContainer2 .profileImageContainer {
    border: 2px solid #444;
    position: relative;
  }
  .teamMembersDisplayContainer2 .profileImageContainer::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 1/1;
    background-color: #000000aa;
    background-image: linear-gradient(0deg, #00000077, #00000011);
    border-radius: 50%;
    z-index: 500;
  }
}

.teamMembersDisplayContainer,
.teamMembersDisplayContainer2 {
  transition: all ease-in-out 150ms;
  scale: 0.95;
  opacity: 0;
}

.teamMembersDisplayContainer2 {
  transition-delay: 100ms;
}

.currentDepartmentDoneAppear {
  scale: 0;
}

.currentDepartmentActiveExit {
  opacity: 0;
}

.currentDepartmentDoneEnter {
  scale: 1;
  opacity: 1;
}

.currentDepartmentExit {
  scale: 0.95;
}

/* .currentDepartmentAppear {
  background-color: red;
}
.currentDepartmentActiveAppear {
  background-color: red;
}
.currentDepartmentDoneAppear {
  background-color: red;
}
.currentDepartmentEnter {
  background-color: red;
}
.currentDepartmentActiveEnter {
  background-color: red;
}
.currentDepartmentDoneEnter {
  background-color: red;
}
.currentDepartmentExit {
  background-color: red;
}
.currentDepartmentActiveExit {
  background-color: red;
}
.currentDepartmentDoneExit {
  background-color: red;
} */
