.ticker {
  display: flex;
}

.digit {
  position: relative;
  overflow: hidden;
  height: 41px;
  width: 1.25rem;
}

.digitTrack {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 41px;
  line-height: 41px;
  opacity: 0;
  transition: translate 3s cubic-bezier(0.1, 0.67, 0.1, 1),
    opacity ease-in-out 0.25s;
}
