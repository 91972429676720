.footer {
  position: relative;
  padding: 1rem;
  padding-top: 120px;
  background-image: none;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: #111;
}

.visible {
  background-image: url("../img/world_map_5.svg");
}

.footer::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, #000, #00000000 100%);
}

.footer::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 325px;
  background: linear-gradient(0deg, #000, #00000000 100%);
  z-index: 0;
}

.footer .footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ctaTitle {
  margin-bottom: 1rem !important;
  font-size: 40px !important;
}

.ctaText {
  color: var(--text-light);
  margin-bottom: 3rem;
  text-align: center;
  font-size: 16px;
}

.ctaLink {
  background-color: var(--color-light);
  padding: 0.75rem 0.5rem;
  color: var(--text-dark);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  text-decoration: none;
  max-width: 300px;
  border-radius: 37.5rem;
  line-height: 1.5rem;
  margin-bottom: 3rem;
  transition: background-color ease-in-out 250ms, color ease-in-out 200ms;
}

.ctaLink:hover {
  background-color: var(--color-accent);
  color: var(--text-light);
}

.statGrid {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  margin-top: 1rem;
  border-bottom: #fff 2px solid;
}

.statGrid .stat:nth-child(1),
.statGrid .stat:nth-child(2) {
  margin-bottom: 3rem;
}

.statGrid .stat {
  width: 50%;
}

.statGrid .icon {
  margin-bottom: 1rem;
  height: 50px;
  width: auto;
}

.statGrid .text {
  text-transform: uppercase;
  color: #fff;
  font-size: 26px;
  font-family: var(--secondary-font);
}

.statGrid .character {
  color: var(--color-accent);
  font-size: 41px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: var(--secondary-font);
  line-height: 41px;
}

.stat {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-bottom: 0.5rem;
}

.footer h4 {
  font-size: 36px;
  text-transform: uppercase;
  color: var(--color-light);
  text-align: center;
  margin-bottom: 2rem;
}

.footer .description {
  display: none;
}

.footer .buttonLink {
  display: block;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  border-radius: 20px;
  margin-bottom: 1rem;
}

.footer .buttonLink:nth-of-type(1) {
  background-color: var(--color-accent);
}

.footer .buttonLink:nth-of-type(2) {
  background-color: var(--color-light);
  color: var(--color-dark);
  margin-bottom: 6rem;
}

.footer .ttLogo {
  margin-bottom: 4rem;
  width: 200px;
  height: auto;
  z-index: 1;
}

.footer .brandsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: #545454 1px solid;
  z-index: 1;
  max-width: 768px;
}

.footer .brandsContainer img {
  height: auto;
  width: 100%;
  max-width: 100px;
}

.footer nav {
  margin-bottom: 1.5rem;
  z-index: 1;
}

.footer nav ul {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  list-style: none;
  gap: 1rem;
  text-align: center;
}

.footer nav ul li a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  text-align: center;
  transition: background-color ease-in-out 250ms;
}

.footer nav ul li a:hover {
  background-color: var(--color-accent);
}

.footer .buttonLink {
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  border-radius: 20px;
  margin-bottom: 1rem;
  z-index: 1;
  transition: background-color ease-in-out 250ms, color ease-in-out 250ms;
}

.footer .buttonLink:hover {
  background-color: var(--color-accent);
  color: var(--color-light);
}

.footer .buttonLink:last-of-type {
  border: 1px solid var(--color-accent);
}

.footer .buttonLink:first-of-type:hover {
  background-color: var(--color-light);
  color: var(--color-dark);
}

.footer .buttonLink:last-of-type:hover {
  background-color: var(--color-accent);
}

.noticePolicies {
  position: relative;
  background-color: #b60e0e;
  color: #fff;
  padding-top: 2rem;
}

.noticePoliciesContainer {
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.noticePolicies nav {
  margin-bottom: 1rem;
}

.noticePolicies nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  list-style: none;
}

.noticePolicies nav ul li a {
  display: block;
  color: #fff;
  text-decoration: none;
  text-align: center;
  margin-bottom: 0.5rem;
  border-radius: 20px;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  transition: border ease-in-out 250ms, colour ease-in-out 250ms,
    background-color ease-in-out 250ms;
}

.noticePolicies nav ul li a:hover {
  border: 1px solid var(--color-dark);
  background-color: var(--color-light);
  color: var(--color-dark);
}

.noticePolicies p {
  text-align: center;
  margin-bottom: 1.5rem;
}

@media (width >= 400px) {
  .footer nav ul {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 667px) {
  .footer nav ul {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (width >= 768px) {
  .footer .description {
    display: unset;
    text-align: center;
    margin-bottom: 2.5rem;
    color: var(--color-light);
    font-size: 18px;
    padding: 0 2rem;
    max-width: 830px;
  }

  .footerContainer {
    margin-top: 80px;
  }
}

@media (width >= 1160px) {
  .statGrid {
    flex-wrap: nowrap;
    gap: 3rem;
  }

  .statGrid .stat {
    width: unset;
  }

  .statGrid .stat:nth-child(1),
  .statGrid .stat:nth-child(2) {
    margin-bottom: unset;
  }
}

@media (width >= 1240px) {
  .noticePolicies {
    padding: 1rem;
  }

  .noticePoliciesContainer {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }

  .noticePolicies nav {
    margin: 0;
  }

  .noticePolicies nav ul {
    flex-direction: unset;
  }

  .noticePolicies nav ul li a {
    margin: 0;
  }

  .noticePoliciesContainer p {
    margin: 0;
  }
}
