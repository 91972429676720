.mobileNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: #000000d9;
  z-index: 999;
  padding: 0.5rem 0.5rem;
}

.logo {
  height: 100%;
}

.logo img {
  height: 100%;
  object-fit: contain;
}

.menuButton {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 70%;
  aspect-ratio: 2/1.5;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.menuButton .menuBar {
  background-color: var(--color-light);
  width: 100%;
  height: 4px;
  transition: all ease-in-out 250ms;
  border-radius: 2px;
}

.menuButton.open .menuBar:nth-of-type(1) {
  transform: rotate(45deg) translate(0px, 13px);
  background-color: var(--color-accent);
  z-index: 3;
}

.menuButton.open .menuBar:nth-of-type(2) {
  background-color: var(--color-accent);
  opacity: 0;
  width: 0%;
}

.menuButton.open .menuBar:nth-of-type(3) {
  transform: rotate(-45deg) translate(-1px, -12px);
  background-color: var(--color-accent);
  z-index: 3;
}

.menu {
  position: absolute;
  top: 60px;
  right: -100%;
  height: calc(100vh - 60px);
  padding: 1.5rem;
  background-color: #000000dd;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: right ease-in-out 250ms;
}

.menu.open {
  right: 0;
}

.menu ul {
  list-style: none;
  text-align: right;
}

.menu ul li {
  margin-bottom: 2rem;
}

.menu ul a {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.25rem;
  padding: 0.5rem 0.75rem;
  border-radius: 27px;
}

.joinLink {
  display: block;
  margin-top: 1.25rem;
  padding: 0.5rem 0.75rem;
  border: 1px solid var(--color-accent);
  border-radius: 20px;
  color: var(--color-light);
  text-decoration: none;
  text-transform: uppercase;
}

.shopLink {
  display: block;
  margin-top: 1.25rem;
  padding: 0.5rem 0.75rem;
  border: 1px solid var(--color-accent);
  border-radius: 20px;
  color: var(--color-light);
  background-color: var(--color-accent);
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color ease-in-out 250ms;
}

.nav {
  display: none;
}

@media (width >= 1044px) {
  .mobileNav {
    display: none;
  }

  .nav {
    position: fixed;
    display: block;
    top: 0;
    width: 100%;
    z-index: 999;
    height: 112px;
    background-color: #000000d9;
    padding: 1rem;
  }

  .nav .navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .nav .navLinks ul {
    display: flex;
    list-style: none;
    justify-content: space-around;
  }

  .nav .navLinks ul li a {
    text-decoration: none;
    color: var(--color-light);
    text-transform: uppercase;
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    transition: border ease-in-out 250ms;
  }

  .nav .navLinks ul li a:hover {
    /* background-color: var(--color-accent); */
    border: 1px solid var(--color-accent);
  }

  .externalLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .joinLink,
  .shopLink {
    margin-top: 0;
    transition: background-color ease-in-out 250ms;
  }

  .joinLink:hover {
    background-color: var(--color-accent);
  }

  .shopLink:hover {
    background-color: var(--color-dark);
  }
}

@media (width >= 1100px) {
  .nav .navLinks ul {
    gap: 1rem;
  }
}
